<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part mb-0">
      <app-flow-welcome></app-flow-welcome>

      <div class="environment-indicator-nagative">
        <app-environment-indicator [readOnly]="true"></app-environment-indicator>
      </div>
    </div>

    <div class="content-part" [ngClass]="{'validation-visible': showFieldValidation()}">
      <h2>{{ 'pages.flow.mfa_code.enter_security_code_email' | translate }}</h2>

      <div class="form-group styled floating-label mb-1">
        <input id="input_mfa_code_security_code" type="text" class="form-control w-100"
          [placeholder]="('pages.flow.mfa_code.security_code' | translate)"
          [ngClass]="{'ng-invalid-important': showFieldValidation()}" [(ngModel)]="codeInput">
        <label for="label_mfa_code_security_code">{{ 'pages.flow.mfa_code.security_code' | translate }}</label>
        <p id="feedback_mfa_code_code_invalid" class="validation-feedback general-feedback" *ngIf="codeIncorrect">{{
          'pages.flow.mfa_code.code_invalid' | translate }}</p>
        <p id="feedback_mfa_code_enter_security_code" class="validation-feedback general-feedback"
          *ngIf="codeEmpty && !codeInput">{{ 'pages.flow.mfa_code.enter_security_code' | translate }}</p>
      </div>

      <p class="mb-0"><a href="" class="text-link" id="button_mfa_resend_code" (click)="submitRequestNewCode($event)">{{
          'pages.flow.mfa_code.resend_code' | translate }}</a></p>

      <button id="button_mfa_code_submit" type="submit" class="btn btn-sm btn-secondary mt-2 mt-lg-3"
        [ngClass]="{'loader': isLoading}" (click)="submit()">
        <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
        <span>{{ 'action.sign_in' | translate }}</span>
      </button>
    </div>


    <div class="footer-part">
    </div>
  </div>
</div>

<div class="layout-body fixed with-background">
  <div class="layout-background"></div>
</div>
