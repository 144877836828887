<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part mb-0">
      <app-flow-welcome></app-flow-welcome>

      <div class="environment-indicator-nagative">
        <app-environment-indicator [readOnly]="true"></app-environment-indicator>
      </div>
    </div>

    <div class="content-part mb-2">
      <h2>{{ 'pages.flow.forgot_password_reset.title' | translate }}</h2>

      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()"
        [ngClass]="{'validation-visible': validationVisible}">
        <div class="form-group styled floating-label mb-2">
          <input id="input_forgot_password_new_pw" type="password" class="form-control w-100"
            [placeholder]="('pages.flow.forgot_password_reset.new_pw'| translate)" formControlName="new_password"
            [ngClass]="{'ng-invalid-important': (form.errors?.mismatch)}">
          <label id="label_forgot_password_new_pw" for="input_forgot_password_new_pw">{{
            'pages.flow.forgot_password_reset.new_pw' | translate }}</label>
          <p id="feedback_forgot_password_pw_complexity_error" class="validation-feedback"
            *ngIf="form?.controls?.new_password?.errors?.password_policy">{{
            'pages.flow.expired_password_reset.pw_complexity_error' |
            translate }}</p>
          <p id="feedback_forgot_password_already_sed_error" class="validation-feedback"
            *ngIf="form?.controls?.new_password?.errors?.password_already_used">{{
            'pages.flow.expired_password_reset.pw_already_used' |
            translate:{value: policy?.history_size} }}</p>
        </div>

        <div class="form-group styled floating-label mb-2">
          <input id="input_forgot_password_confirm_new_pw" type="password" class="form-control w-100"
            [placeholder]="('pages.flow.forgot_password_reset.confirm_new_pw'|translate)"
            formControlName="new_password_verification" [ngClass]="{'ng-invalid-important': form.errors?.mismatch}">
          <label id="label_forgot_password_confirm_new_pw" for="input_forgot_password_confirm_new_pw">{{
            'pages.flow.forgot_password_reset.confirm_new_pw' | translate }}</label>
          <p id="feedback_forgot_password_pw_not_the_same" class="validation-feedback general-feedback"
            *ngIf="form?.errors?.mismatch">{{ 'pages.flow.expired_password_reset.pw_not_the_same' | translate }}</p>
        </div>

        <button id="button_forgot_password_submit" type="submit" class="btn btn-sm btn-secondary mt-1"
          [ngClass]="{'loader': isLoading}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
          <span>{{ 'pages.flow.forgot_password_reset.submit' | translate }}</span>
        </button>
      </form>
    </div>

    <div class="content-part" *ngIf="policy">
      <app-password-policy-rules [policy]="policy" [validationVisible]="validationVisible"></app-password-policy-rules>
    </div>

    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>
<div class="layout-body fixed with-background">
  <div class="layout-background"></div>
</div>
