<div class="layout-sidebar">
  <div class="sidebar-content is-flexed">
    <div class="content-part">
      <h1 class="display-1">{{ 'pages.flow.home.welcome' | translate }}</h1>

      <div class="environment-indicator-nagative">
        <app-environment-indicator></app-environment-indicator>
      </div>

      <h2>{{ 'pages.flow.home.pls_sign_in' | translate }}</h2>
      <form [formGroup]="form" id="form" (ngSubmit)="formSubmit()" [ngClass]="{'validation-visible': isValidationVisible}">
        <div class="form-group styled floating-label mb-2">
          <input type="email" class="form-control w-100" [placeholder]="('form.labels.email_address' | translate | titlecase)" formControlName="email"  [ngClass]="{'ng-invalid-important': credentialsIncorrect}">
          <label for="input_home_login_email_address">{{ 'form.labels.email_address' | translate | titlecase }}</label>
          <app-feedback-field [field]="form.get('email')"></app-feedback-field>
        </div>

        <div class="form-group styled floating-label mb-2">
          <input type="password" class="form-control w-100" [placeholder]="('form.labels.password' | translate | titlecase)" formControlName="password" [ngClass]="{'ng-invalid-important': credentialsIncorrect}">
          <label for="input_home_login_password">{{ 'form.labels.password' | translate  | titlecase}}</label>
          <p class="validation-feedback general-feedback" *ngIf="credentialsIncorrect">{{ 'pages.flow.home.email_or_pw_incorrect' | translate }}</p>
          <p class="validation-feedback general-feedback" *ngIf="isAccountLocked">{{ 'pages.flow.home.account_locked' | translate }}</p>
        </div>

        <p class="mb-0">
          <a id="link_home_forgot_pw" [routerLink]="['forgot-password']" class="text-link">{{ 'pages.flow.home.forgot_password' | translate }}</a>
        </p>

        <button id="button_home_login_submit" type="submit" class="btn btn-sm btn-secondary mt-3 mt-lg-4" [ngClass]="{'loader': isLoading}">
          <span class="loader"><em class="spinner-border spinner-border-sm" *ngIf="isLoading"></em></span>
          <span>{{ 'action.sign_in' | translate }}</span>
        </button>
      </form>
    </div>
    <div class="footer-part">
      <app-flow-copyrights></app-flow-copyrights>
    </div>
  </div>
</div>
<div class="layout-body fixed with-background">
  <div class="layout-background"></div>
</div>
