<div class="default-layout" [ngClass]="{'open': sidebarOpen}">
  <div class="default-layout-backdrop" [ngClass]="{'visible': backdropVisible, 'shown': backdropShown}" (click)="closeSidebarHandler($event);"></div>
  <div class="layout-sidebar">
    <div class="sidebar-content is-flexed">
      <div class="content-part">
        <div class="sidebar-toggle">
          <a href="" class="toggle-closed" [inlineSVG]="'/assets/svg/hamburger.svg'" (click)="openSidebarHandler($event);"></a>

          <a href="" class="toggle-open scale-up-2"  (click)="closeSidebarHandler($event);">
            <span class="rtl-d-none" [inlineSVG]="'/assets/svg/chevron-left.svg'"></span>
            <span class="d-none rtl-d-inline" [inlineSVG]="'/assets/svg/chevron-right.svg'"></span>
          </a>

          <h1 class="m-3">{{ 'general.title' | translate }}</h1>
        </div>
        <app-environment-indicator></app-environment-indicator>
      </div>

      <div class="content-part mb-5">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_hospital_admin' | translate }}</p>

        <div class="nav-item" [ngClass]="{'active': router.url === '/hospitals'}">
          <a href="" class="nav-link" [routerLink]="['/hospitals']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/stethoscope.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_hospitals' | translate }}</div>
          </a>
        </div>

        <p class="sidebar-title sidebar-open-element-block mt-5">{{ 'layouts.default.nav.title_platform_admin' | translate }}</p>
        <div class="nav-item" [ngClass]="{'active': router.url === '/admin-users'}" *ngIf="hasRole('ROLE_USER_ADMIN')">
          <a href="" class="nav-link" [routerLink]="['/admin-users']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/community.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_ops_users' | translate }}</div>
          </a>
        </div>

        <div class="nav-item" [ngClass]="{'active': router.url === '/applications'}" *ngIf="hasRole('ROLE_ORG_ADMIN')">
          <a href="" class="nav-link" [routerLink]="['/applications']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/app.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.applications' | translate }}</div>
          </a>
        </div>

        <div class="nav-item" [ngClass]="{'active': router.url.includes('platform-consents')}" *ngIf="hasRole('ROLE_ORG_ADMIN')">
          <a href="" class="nav-link" [routerLink]="['/platform-consents']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/multpage.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.consents' | translate }}</div>
          </a>
        </div>

        <div class="nav-item has-subnav" [ngClass]="{'is-collapsed': isSubnavCollapsed('pathways'), 'active': router.url.includes('pathway-templates') || router.url.includes('camunda-cockpit-links')}" *ngIf="hasRole('ROLE_ORG_ADMIN')">
          <a href="#subnav-pathways" class="nav-link" (click)="toggleSubnav($event, 'pathways')" role="button" aria-controls="subnav-pathways">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/templates.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.pathways' | translate }}</div>
            <span class="chevron ml-auto" [inlineSVG]="'/assets/svg/chevron-down.svg'"></span>
          </a>
          <div class="nav-item-subnav" id="subnav-pathways" [collapse]="isSubnavCollapsed('pathways')" *ngIf="sidebarOpen">
            <a href="" class="subnav-link" [ngClass]="{'active': router.url.includes('pathway-templates')}" [routerLink]="['/pathway-templates']">{{ 'layouts.default.nav.templates' | translate }}</a>
            <a href="" class="subnav-link" [ngClass]="{'active': router.url.includes('camunda-cockpit-links')}" [routerLink]="['/camunda-cockpit-links']">{{ 'layouts.default.nav.camunda_cockpit_links' | translate }}</a>
          </div>
        </div>

        <div class="nav-item" [ngClass]="{'active': router.url.includes('gdpr-admin')}" *ngIf="hasRole('ROLE_ORG_ADMIN')">
          <a href="" class="nav-link" id="sidebar-dashboard" [routerLink]="['/gdpr-admin']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/gdpr.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.gdpr_admin' | translate }}</div>
          </a>
        </div>

        <div class="nav-item" [ngClass]="{'active': router.url.includes('platform-configuration')}" *ngIf="hasRole('ROLE_ORG_ADMIN')">
          <a href="" class="nav-link" id="sidebar-dashboard" [routerLink]="['/platform-configuration']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/platform-config.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_platform_configuration' | translate }}</div>
          </a>
        </div>
      </div>
      <div class="content-part mt-auto mb-0">
        <p class="sidebar-title sidebar-open-element-block">{{ 'layouts.default.nav.title_account' | translate }}</p>

        <div class="nav-item" [ngClass]="{'active': router.url.includes('account-settings')}">
          <a class="nav-link" id="sidebar-account-settings"  [routerLink]="['account-settings']">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/settings.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_settings' | translate }}</div>
          </a>
        </div>

        <div class="nav-item">
          <a href="" class="nav-link" id="sidebar-logout"  (click)="showLogoutModal($event)">
            <div class="icon rtl-mirrored-inline-svg" [inlineSVG]="'/assets/svg/logout.svg'"></div>
            <div class="label">{{ 'layouts.default.nav.item_logout' | translate }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-body">
    <router-outlet></router-outlet>
  </div>
</div>
